




import { Component, Vue } from 'vue-property-decorator'
import SearchDetail from './components/SearchDetail.vue'

@Component({
  name: 'EditSearch',
  components: {
    SearchDetail
  }
})
export default class extends Vue {}
